import GoogleMapReact from 'google-map-react';
import { FaExclamationCircle } from 'react-icons/fa';

function Delivery() {
    
    return ( <div style={{ display:'flex',alignItems:"center",justifyContent:"center",height: '500px', width: '100%' }}>
<FaExclamationCircle color='gray' size={40}/> Page is not ready
  </div>);
}

export default Delivery;